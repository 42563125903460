<template>
  <div class="info-card">
    <icon-box :icon="icon" :size="1.4" />
    <span>{{$t(content)}}</span>
  </div>
</template>

<script>
import IconBox from "@/components/common/IconBox";
export default {
  name: "MobileInfoCard",
  components: {
    "icon-box": IconBox
  },
  props: {
    icon: {
      type: String,
      default: ""
    },
    content: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped>
.info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-card span {
  font-size: calc(var(--oh) * 1.7);
  line-height: 180%;
  font-weight: 600;
  color: #2E293D;
}
</style>