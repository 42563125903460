<template>
  <div id="info">
    <div class="info-upper">
      <div class="header-container">
        <h1>{{$t("FEATURES_HEADER")}}</h1>
        <span>{{$t("FEATURES_CONTENT")}}</span>
      </div>
      <div class="features-container">
        <info-card class="info-card" icon="scan" content="FEATURE_SCAN_HEADER" />
        <info-card class="info-card" icon="ocr" content="FEATURE_OCR_HEADER" />
        <info-card class="info-card" icon="signature" content="FEATURE_SIGNATURE_HEADER" />
        <info-card class="info-card" icon="edit" content="FEATURE_EDIT_HEADER" />
        <info-card class="info-card" icon="share" content="FEATURE_SHARE_HEADER" />
        <info-card class="info-card" icon="translate" content="FEATURE_TRANSLATE_HEADER" />
      </div>
    </div>
    <div class="info-lower">
      <div class="lower-header-container">
          <h1>{{$t("INFO_HEADER")}}</h1>
        <span>{{$t("INFO_CONTENT")}}</span>
      </div>
      <store-badges :scale="0.7" />
    </div>
  </div>
</template>

<script>
import MobileInfoCard from "@/components/mobile/InfoCard";
import StoreBadges from "@/components/common/StoreBadges";
export default {
  name: "Info",
  components: {
    "info-card": MobileInfoCard,
    "store-badges": StoreBadges
  }
}
</script>

<style scoped>
#info {
  width: 100vw;
  height: calc(var(--oh) * 100);

}
.info-upper {
  width: 100%;
  height: 66%;
  background: #F5F7FE;
  padding: calc(var(--oh) * 5.6) 4vw;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.info-lower {
  width: 100%;
  height: 34%;
  background: #FF8289;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header-container h1 {
  margin: calc(var(--oh) * 1.4) 5vw;
  font-size: calc(var(--oh) * 3.2);
  line-height: calc(var(--oh) * 3.7);
  text-align: center;
  color: #2E293D;
}
.header-container span {
  margin: 0 8vw;
  font-size: calc(var(--oh) * 1.8);
  line-height: 150%;
  text-align: center;
  color: #A7A5B2;
}

.features-container {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.info-card {
  width: 30%;
}

.lower-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lower-header-container h1 {
  margin: calc(var(--oh) * 1.4) 13vw;
  font-size: calc(var(--oh) * 3.2);
  line-height: calc(var(--oh) * 3.7);
  text-align: center;
  color: white;
}
.lower-header-container span {
  margin: 0 15vw;
  font-size: calc(var(--oh) * 1.8);
  line-height: 150%;
  text-align: center;
  color: white;
}
</style>